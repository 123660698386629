







import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import { Seminar } from "@/core/models";
import SeminarList from "../components/SeminarList.vue";

@Component({ components: { SeminarList } })
export default class SeminarsHome extends Vue {
  @Getter("seminars/selected") selected?: Seminar;
  @Getter("seminars/seminars") seminars!: Seminar[];
  @Getter("seminars/gettingAll") gettingAll!: boolean;
  @Getter("seminars/ownsSeminar") isOwner!: boolean;
  @Action("seminars/getAll") getAll!: () => Promise<void>;

  @Watch("seminars", { immediate: true })
  seminarsLengthChanged() {
    if (!this.seminars.length) return;
    const id = this.selected ? this.selected.id : this.seminars[0].id;
    const from = this.$route.query.from as string | undefined;
    if (!from || from === "dash")
      this.$router.replace(`/seminars/dashboard/${id}`);
    else this.$router.replace(`/seminars/details/${id}`);
  }

  created() {
    this.getAll();
  }
}
